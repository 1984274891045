import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outlet',
  templateUrl: './outlet.component.html',
  styleUrls: ['./outlet.component.css']
})
export class OutletComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(function(){
      //@ts-ignore
    	$('#carousel1').carousel({ pause: "false" });
      //@ts-ignore
    	$('#carousel2').carousel({ pause: "false" });
    })
  }
}

import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { HeaderComponent } from './header/header.component';
import { VideoComponent } from './video/video.component';
import { OutletComponent } from './outlet/outlet.component';
import { MultibrandComponent } from './multibrand/multibrand.component';
import { MeasureComponent } from './measure/measure.component';
import { MapComponent } from './map/map.component';
import { StoriesComponent } from './stories/stories.component';
import { SubmenuComponent } from './submenu/submenu.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    VideoComponent,
    OutletComponent,
    MultibrandComponent,
    MeasureComponent,
    MapComponent,
    StoriesComponent,
    SubmenuComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

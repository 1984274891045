import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  showVideo = false;
  constructor() { }

  ngOnInit() {
    window.addEventListener('scroll', this.checkScroll.bind(this), false);
  }

  checkScroll() {
    var videoWrapper = document.getElementById('videoAR');
    let rectTop = videoWrapper.getBoundingClientRect().top;
    let distanceToReach = window.innerHeight*(70/100);
    if(rectTop > 0 && rectTop < distanceToReach) {
      setTimeout(() => {
        this.showVideo = true;
        let video: any = document.getElementById('video');
        video.play();
      }, 3000);
    } else if(rectTop < 0) {
      this.showVideo = false;
    }
    let mainDivTop = document.getElementById('mainWrapper').getBoundingClientRect().top;
    if(mainDivTop < 0) {
      document.getElementById('header').style.backgroundColor = 'black';
      document.getElementById('header2').style.backgroundColor = 'black';
    } else {
      document.getElementById('header').style.backgroundColor = 'transparent';
      document.getElementById('header2').style.backgroundColor = 'transparent';
    }
  }
}

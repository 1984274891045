import { Component, OnInit, ViewChild } from '@angular/core';

import { } from 'googlemaps';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  @ViewChild('map', { static: true }) mapElement: any;
  constructor() { }

  ngOnInit() {
    let mapProperties = {
      center: new google.maps.LatLng(19.1363264, 72.8335455),
      zoom: 17,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    if (window.matchMedia('(max-width: 490px)').matches) {
      console.log('inside');
      mapProperties = {
        center: new google.maps.LatLng(19.134357, 72.833808),
        zoom: 17,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
    }
    var map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
    var infowindow = new google.maps.InfoWindow();
    //@ts-ignore
    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(19.1363264, 72.8335455),
      map: map,
      title: 'DesignAR'
    });
    google.maps.event.addListener(marker, 'click', function() {
      window.open(
        'https://www.google.com/maps/place/1st+Floor,+DesignAR,+Modi+House,+Veera+Desai+Industrial+Estate,+Andheri+West,+Mumbai,+Maharashtra+400047/@19.1363416,72.8336541,17z/data=!4m2!3m1!1s0x3be7b72aa089e855:0xcd7de653532161a2',
         '_blank'
      );
    });
  }

}

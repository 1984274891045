import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.css']
})
export class StoriesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.addEventListener('scroll', this.checkScrollForBg.bind(this))
    $(document).ready(function(){
      //@ts-ignore
      $('#carousel3').carousel({ pause: "false" });
      //@ts-ignore
      $('#carousel4').carousel({ pause: "false" });
    })
  }

  checkScrollForBg() {
    let el = document.getElementById('storyTop');
    let rectTop = el.getBoundingClientRect().top;
    let rectBottom = el.getBoundingClientRect().bottom;
    let distanceToReach = window.innerHeight*(50/100);
    if(rectTop > 0 && rectTop < distanceToReach) {
      el.classList.add('bgColorBlack')
    } else if(rectBottom < window.innerHeight*(20/100) || rectTop > window.innerHeight) {
      el.classList.remove('bgColorBlack');
    }

    let el1 = document.getElementById('storyTop2');
    let rectTop1 = el1.getBoundingClientRect().top;
    let rectBottom1 = el1.getBoundingClientRect().bottom;
    if(rectTop1 > 0 && rectTop1 < distanceToReach) {
      el1.classList.add('bgColorBlack')
    } else if(rectBottom1 < window.innerHeight*(20/100) || rectTop1 > window.innerHeight) {
      el1.classList.remove('bgColorBlack');
    }
  }
}
